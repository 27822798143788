.Filtershow {
  position: absolute;
  width: 228px;
  height: 190px;
  left: 75%;
  top: 12%;
  background: #131313;
  border-radius: 15px;
  padding: 25px;
}

.Filtershow p {
  width: 49px;
  height: 24px;

  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  color: #a5a5a5;
}

.Filtershow hr {
  /* position: absolute; */
  width: 158px;
  height: 0px;

  border: 1px solid #cbcbcb;
}

.Filterhide {
  display: none;
}

.Hide {
  display: none;
}

.Drop {
  /* position: absolute; */
  width: 168.45px;
  height: 37.5px;
  color: #a5a5a5;
  background: #232323;
  border-radius: 4.68775px;
  margin-bottom: 5px;
  padding: 5px;
}
